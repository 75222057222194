import React from 'react';

const title = "03 Comments";


const commentList = [
    {
        imgUrl: 'assets/images/blog/author1.jpg',
        imgAlt: 'blog',
        name: 'Alexa anna',
        date: 'August 24th, 2022 at 5:00pm',
        desc: 'Unrtve embace pandmc human are awesome theme aptal ompen plagaze perfoance based theme uthoritativel embrace pandemc',
    },
    {
        imgUrl: 'assets/images/blog/author2.jpg',
        imgAlt: 'blog',
        name: 'Jhon Doe',
        date: 'August 24th, 2022 at 5:00pm',
        desc: 'Unrtve embace pandmc human are awesome theme aptal ompen plagaze perfoance based theme uthoritativel embrace pandemc',
    },
    {
        imgUrl: 'assets/images/blog/author.jpg',
        imgAlt: 'blog',
        name: 'Serena wilie',
        date: 'August 24th, 2022 at 5:00pm',
        desc: 'Unrtve embace pandmc human are awesome theme aptal ompen plagaze perfoance based theme uthoritativel embrace pandemc',
    },
]

const Comment = () => {
    return (
        <div className="widget widget-comment">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body p-0">
                <ul className="comment-area">
                    {commentList.map((val, i) => (
                        <li key={i}>
                            <div className="comment-item">
                                <div className="comment-thumb">
                                    <a href="#"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a>
                                </div>
                                <div className="comment-content">
                                    <div className="comment-header">
                                        <h6 className="title"><a href="#">{val.name}</a></h6>
                                        <a href="#" className="info">{val.date}</a>
                                        <a href="#" className="reply"><i className="fas fa-reply-all"></i>Reply</a>
                                    </div>
                                    <p>{val.desc}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Comment;