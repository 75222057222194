import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import GoogleMap from '../component/sidebar/widget/google-map';

const title = "Leave A Message";

const ContactPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Contact Us'} curPage={'Contact Us'} />
            <section className="contact-section padding-top padding-bottom">
                <div className="container">
                    <div className="contact-form-area">
                        <h4 className="title">{title}</h4>
                        <form className="contact-form" id="contact_form_submit">
                            <div className="form-group">
                                <input type="text" placeholder="Your Name" id="name" name="name" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Your Email" id="email" name="email" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Phone" id="phone" name="phone" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Subject" id="subject" name="subject" />
                            </div>
                            <div className="form-group w-100">
                                <textarea name="message" id="message" placeholder="Your Message"></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="custom-button"><span>Send Message</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <div className="map-section pos-rel">
                <div className="maps">
                    <GoogleMap />
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ContactPage;