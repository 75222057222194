import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const title = "Oops! this page not found";
const desc = "We are Really Sorry But the Page you Requested is Missing :(";
const btnText = "go back to home";

const ErrorPage = () => {
    return (
        <Fragment>
            <section className="error-section min-height-section bg_img" style={{backgroundImage: "url(/assets/images/error/error-bg.jpg)"}}>
                <div className="container">
                    <div className="error-wrapper">
                        <div className="main-thumb">
                            <img src="./assets/images/error/404.png" alt="error" />
                        </div>
                        <div className="error-content">
                            <h3 className="title">{title}</h3>
                            <p>{desc}</p>
                            <Link to="/" className="custom-button"><span>{btnText}</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ErrorPage;