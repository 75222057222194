import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../component/layout/footer'
import Header from '../component/layout/header'
import PageHeader from '../component/layout/page-header'
import Pagination from '../component/sidebar/widget/pagination'


const ClassesData = [
    {
        id: 1,
        imgUrl: 'assets/images/class/class01.jpg',
        authorImg: 'assets/images/class/teacher01.jpg',
        imgAlt: 'class thumb',
        title: 'Religion And History',
        name: 'Joly Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'toddler',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 2,
        imgUrl: 'assets/images/class/class02.jpg',
        authorImg: 'assets/images/class/teacher02.jpg',
        imgAlt: 'class thumb',
        title: 'Art And Color Management',
        name: 'Alex Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'preschool',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 3,
        imgUrl: 'assets/images/class/class03.jpg',
        authorImg: 'assets/images/class/teacher03.jpg',
        imgAlt: 'class thumb',
        title: 'Music And Performance',
        name: 'Jhon Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'programe',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 4,
        imgUrl: 'assets/images/class/class04.jpg',
        authorImg: 'assets/images/class/teacher01.jpg',
        imgAlt: 'class thumb',
        title: 'Religion And History',
        name: 'Joly Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'school',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 5,
        imgUrl: 'assets/images/class/class05.jpg',
        authorImg: 'assets/images/class/teacher02.jpg',
        imgAlt: 'class thumb',
        title: 'Art And Color Management',
        name: 'Alex Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'toddler',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 6,
        imgUrl: 'assets/images/class/class06.jpg',
        authorImg: 'assets/images/class/teacher03.jpg',
        imgAlt: 'class thumb',
        title: 'Music And Performance',
        name: 'Jhon Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'preschool',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 7,
        imgUrl: 'assets/images/class/class07.jpg',
        authorImg: 'assets/images/class/teacher01.jpg',
        imgAlt: 'class thumb',
        title: 'Religion And History',
        name: 'Joly Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'kinder',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 8,
        imgUrl: 'assets/images/class/class08.jpg',
        authorImg: 'assets/images/class/teacher02.jpg',
        imgAlt: 'class thumb',
        title: 'Art And Color Management',
        name: 'Alex Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'programe',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        id: 9,
        imgUrl: 'assets/images/class/class09.jpg',
        authorImg: 'assets/images/class/teacher03.jpg',
        imgAlt: 'class thumb',
        title: 'Music And Performance',
        name: 'Jhon Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        cate: 'school',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
]

const ClassesPage = () => {
    const [items, setItems] = useState(ClassesData);
    const filterItem = (categItem) => {
        const updateItems = ClassesData.filter((curElem) => {
            return curElem.cate === categItem;
        });
        setItems(updateItems);
    }

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Popular Classes'} curPage={'Classes'} />
            <div className="class-section padding-top padding-bottom pos-rel">
                <div className="top-shape-center">
                    <img src="./assets/css/img/gallery1.png" alt="css" />
                </div>
                <div className="bottom-shape-left">
                    <img src="./assets/css/img/bottom-shape.png" alt="css" />
                </div>
                <div className="container">
                    <ul className="filter">
                        <li onClick={() => setItems(ClassesData) }>Show All</li>
                        <li onClick={() => filterItem('toddler') }>Toddler</li>
                        <li onClick={() => filterItem('preschool') }>Preschool</li>
                        <li onClick={() => filterItem('kinder') }>kindergarten</li>
                        <li onClick={() => filterItem('programe') }>pre-k Program</li>
                        <li onClick={() => filterItem('school') }>before/after school</li>
                    </ul>
                    <div className="class-wrapper d-flex flex-wrap">
                        { items.map((elem) => {
                            const { id, imgUrl, imgAlt, authorImg, cate, title, name, profile, price, valided, rotingList, } = elem;
                            return (
                                <div className="class-inner" key={id}>
                                    <div className="class-item">
                                        <div className="class-thumb">
                                            <Link to="/class-single"><img src={imgUrl} alt={imgAlt} /></Link>
                                        </div>
                                        <div className="class-content">
                                            <h5 className="title">
                                                <Link to="/class-single">{title}</Link>
                                            </h5>
                                            <div className="class-meta">
                                                <div className="class-author">
                                                    <div className="thumb">
                                                        <Link to="/teacher-single"><img src={authorImg} alt={imgAlt} /></Link>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="subtitle"><Link to="/teacher-single">{name}</Link></h6>
                                                        <Link to="/teacher-single" className="info">{profile}</Link>
                                                    </div>
                                                </div>
                                                <div className="cost-area">
                                                    <h6 className="subtitle">{price}</h6>
                                                    <span className="info">{valided}</span>
                                                </div>
                                            </div>
                                            <div className="schedule-area">
                                                {rotingList.map((val, i) => (
                                                    <div className="schedule-item" key={i}>
                                                        <h6 className="sub-title">{val.title}</h6>
                                                        <span className="info">{val.info}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Pagination />
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ClassesPage