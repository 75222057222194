import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import CallTwo from '../component/section/call-2';
import Teacher from '../component/section/teacher';
import TeacherTwo from '../component/section/teacher-2';

const TeacherPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Best Teachers'} curPage={'Teacher'} />
            <TeacherTwo />
            <CallTwo />
            <Teacher />
            <Footer />
        </Fragment>
    )
}

export default TeacherPage;