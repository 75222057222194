import React from 'react';
import { Link } from 'react-router-dom';

const cate = "Our School is Best";
const title = "For Your Childs";
const desc = "Prescholer for the apcation testing and enrollment process for publc and private schools the city of alo abu mal kita kores vai";
const btnText = "Get Started Now";

const Banner = () => {
    return (
        <section className="banner-section bg_img" style={{backgroundImage: "url(/assets/images/banner/banner01.png)"}}>
            <div className="container">
                <div className="banner-content cl-white">
                    <h3 className="cate">{cate}</h3>
                    <h1 className="title">{title}</h1>
                    <p>{desc}</p>
                    <Link to="/login" className="custom-button"><span>{btnText}</span></Link>
                </div>
            </div>
        </section>
    )
}

export default Banner