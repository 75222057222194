import React from 'react';
import { Link } from 'react-router-dom';

const cate = "Our School is Best";
const title = "For Your Childs";
const desc = "Prescholer for the apcation testing and enrollment process for publc and private schools the city of alo abu mal kita kores vai kitton is best for your childs.";
const btnText = "Get Started Now";


const BannerTwo = () => {
    return (
        <section className="banner-section bg_img style-2 oh" style={{backgroundImage: "url(/assets/images/banner/banner02.jpg)"}}>
            <div className="top-shape-content d-none d-lg-block">
                <img src="./assets/images/banner/banner04.png" alt="banner" />
            </div>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="banner-content style-2 cl-white w-100">
                        <h3 className="cate">{cate}</h3>
                        <h1 className="title">{title}</h1>
                        <p>{desc}</p>
                        <Link to="/login" className="custom-button"><span>{btnText}</span></Link>
                    </div>
                    <div className="banner-thumb d-none d-lg-block">
                        <img src="./assets/images/banner/banner03.png" alt="banner" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerTwo