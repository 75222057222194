import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

const btnText = "Get Started Now";

const GalleryData = [
    {
        id: 1,
        cate: 'toddler',
        imgUrl: 'assets/images/gallery/g11.jpg',
        imgAlt: 'gallery',
        title: 'Edge Catalysts For Change',
        desc: 'Start Ups | Resources',
    },
    {
        id: 2,
        cate: 'preschool',
        imgUrl: 'assets/images/gallery/g12.jpg',
        imgAlt: 'gallery',
        title: 'Edge Catalysts For Change',
        desc: 'Financial | Human',
    },
    {
        id: 3,
        cate: 'kinder',
        imgUrl: 'assets/images/gallery/g13.jpg',
        imgAlt: 'gallery',
        title: 'Edge Catalysts For Change',
        desc: 'Start Ups | Resources',
    },
    {
        id: 4,
        cate: 'programe',
        imgUrl: 'assets/images/gallery/g14.jpg',
        imgAlt: 'gallery',
        title: 'Plagiarze Reable Bandwdth',
        desc: 'Resources | Strategy',
    },
    {
        id: 5,
        cate: 'toddler',
        imgUrl: 'assets/images/gallery/g15.jpg',
        imgAlt: 'gallery',
        title: 'Cuting Edge Mindshare Visa',
        desc: 'Start Ups | Resources',
    },
    {
        id: 6,
        cate: 'kinder',
        imgUrl: 'assets/images/gallery/g16.jpg',
        imgAlt: 'gallery',
        title: 'Edge Catalysts For Change',
        desc: 'Start Ups | Resources',
    },
    {
        id: 7,
        cate: 'preschool',
        imgUrl: 'assets/images/gallery/g17.jpg',
        imgAlt: 'gallery',
        title: 'Plagiarze Reable Bandwdth',
        desc: 'Resources | Strategy',
    },
    {
        id: 8,
        cate: 'programe',
        imgUrl: 'assets/images/gallery/g10.jpg',
        imgAlt: 'gallery',
        title: 'Cuting Edge Mindshare Visa',
        desc: 'Start Ups | Resources',
    },
]

const GalleryThree = () => {
    const [items, setItems] = useState(GalleryData);
    const filterItem = (categItem) => {
        const updateItems = GalleryData.filter((curElem) => {
            return curElem.cate === categItem;
        });
        setItems(updateItems);
    }


    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our School Photos'} curPage={'Gallery'} />
            <div className="gallery-section padding-top padding-bottom pos-rel">
                <div className="top-shape-center">
                    <img src="./assets/css/img/gallery1.png" alt="css" />
                </div>
                <div className="bottom-shape-left">
                    <img src="./assets/css/img/bottom-shape.png" alt="css" />
                </div>
                <div className="container">
                    <ul className="filter-2">
                        <li onClick={() => setItems(GalleryData) }><span className="category">Show All</span><span className="amount">08</span></li>
                        <li onClick={() => filterItem('toddler') }><span className="category">toddlers</span><span className="amount">02</span></li>
                        <li onClick={() => filterItem('preschool') }><span className="category">preschool</span><span className="amount">02</span></li>
                        <li onClick={() => filterItem('kinder') }><span className="category">pre-k program</span><span className="amount">02</span></li>
                        <li onClick={() => filterItem('programe') }><span className="category">kindergarten</span><span className="amount">02</span></li>
                    </ul>
                    <div className="masonary-wrapper lg-three-items d-flex flex-wrap justify-content-center">
                        { items.map((elem) => {
                            const { id, imgUrl, imgAlt, title, desc } = elem;
                            return (
                                <div className="masonary-item preschool programe" key={id}>
                                    <div className="gallery-item-3">
                                        <div className="gallery-thumb">
                                            <a className="img-pop" href={imgUrl} target="_blank">
                                                <img src={imgUrl} alt={imgAlt} />
                                            </a>
                                        </div>
                                        <div className="gallery-content">
                                            <h5 className="title">{title}</h5>
                                            <span>{desc}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="load-more">
                        <Link to="/login" className="custom-button"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default GalleryThree;