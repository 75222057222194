import React from 'react';
import CountUp from 'react-countup';

const counterList = [
    {
        imgUrl: 'assets/images/counter/counter4.png',
        imgAlt: 'counter',
        countNumber: '830.250',
        cate: 'Age 03 - 04',
    },
    {
        imgUrl: 'assets/images/counter/counter5.png',
        imgAlt: 'counter',
        countNumber: '26',
        cate: 'Age 10 - 12',
    },
    {
        imgUrl: 'assets/images/counter/counter6.png',
        imgAlt: 'counter',
        countNumber: '100',
        cate: 'Total Boys',
    },
    {
        imgUrl: 'assets/images/counter/counter7.png',
        imgAlt: 'counter',
        countNumber: '100',
        cate: 'Total Girls',
    },
]

const CounterTwo = () => {
    return (
        <div className="counter-section padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/counter/counter-bg.jpg)"}}>
            <div className="container">
                <div className="row justify-content-center mb-30-none">
                    {counterList.map((val, i) => (
                        <div className="col-xl-3 col-md-6 col-sm-6" key={i}>
                            <div className="counter-item round-style">
                                <div className="counter-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                                <div className="counter-content">
                                    <div className="counter-header">
                                        <h2 className="title"><CountUp end={val.countNumber} /></h2>
                                    </div>
                                    <span className="cate">{val.cate}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CounterTwo