import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Archive from '../component/sidebar/widget/archive';
import Author from '../component/sidebar/widget/author';
import Comment from '../component/sidebar/widget/comment';
import CommentFrom from '../component/sidebar/widget/comment-from';
import GalleryPost from '../component/sidebar/widget/gallery-post';
import PopularCategory from '../component/sidebar/widget/popular-category';
import PopularPost from '../component/sidebar/widget/popular-post';
import PopularTag from '../component/sidebar/widget/popular-tag';
import Search from '../component/sidebar/widget/search';


const shareList = [
    {
        iconName: 'fab fa-facebook-f',
        className: 'facebook',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-dribbble',
        className: 'dribbble',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-google-plus-g',
        className: 'google',
        siteLink: '#',
    },
]

const tagsList = [
    {
        text: 'Schools',
        Link: '#',
    },
    {
        text: 'Education',
        Link: '#',
    },
    {
        text: 'Admission',
        Link: '#',
    },
    {
        text: 'Exams',
        Link: '#',
    },
]



const BlogSingle = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Serenity Hassr Taken...'} curPage={'Blog Details'} />
            <div className="blog-section padding-top padding-bottom">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-lg-8 mb-50">
                            <div className="class-single-item mb-30">
                                <div className="class-single-inner">
                                    <div className="class-single-thumb">
                                        <img src="./assets/images/blog/blog15.jpg" alt="class" />
                                    </div>
                                    <div className="class-single-content">

                                        <ul className="meta-post">
                                            <li>
                                                <a href="#"><i className="far fa-user"></i> William Khanna</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="far fa-heart"></i>124 Likes</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="far fa-comments"></i>09 Comments</a>
                                            </li>
                                        </ul>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                        <blockquote className="single-quote">
                                            <div className="quotes">
                                                Steal into The nering Sanc How Mysef Down Amon The Hal Gras Buz
                                                Lttle World Amon The Stak And Grow Fama With Couns And Fesingri
                                                Steal intoing Thene Sanc Hrow Myse Down Amon The Hall Gras Biss
                                                The Almighty Among The Staks
                                                <span>...Melissa Hunter</span>
                                            </div>
                                        </blockquote>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                        <div className="thumb-area">
                                            <img src="./assets/images/blog/blog14.jpg" alt="blog" />
                                        </div>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                        <div className="video-area">
                                            <img src="./assets/images/blog/blog16.jpg" alt="blog" />
                                            <a href="https://www.youtube.com/embed/6E9J8biF8RE" className="video-button popup"><i className="flaticon-play"></i></a>
                                        </div>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                    </div>
                                    <div className="tags-area">
                                        <ul className="tags">
                                            {tagsList.map((val, i) => (
                                                <li key={i}>
                                                    <a href={val.Link}>{val.text}</a>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="share">
                                            {shareList.map((val, i) => (
                                                <li key={i}>
                                                    <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Author />
                            <Comment />
                            <CommentFrom />
                        </div>
                        <div className="col-lg-4 mb-50">
                            <aside>
                                <Search />
                                <PopularCategory />
                                <PopularPost />
                                <Archive />
                                <GalleryPost />
                                <PopularTag />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default BlogSingle;