import React from 'react';
import AboutThree from './about-3';

const cate = "Welcome to Our kitton School";
const title = "Best Education in Our Kindergarden";


const featureList = [
    {
        imgUrl: 'assets/images/feature/01.png',
        imgAlt: 'feature',
        title: 'Toddler',
        age: '(1,5 – 2 years)',
        desc: 'Since have been vsnary arenre reabe software enneern partne have been and visionary',
    },
    {
        imgUrl: 'assets/images/feature/02.png',
        imgAlt: 'feature',
        title: 'Pre-K Program',
        age: '(4 – 5 years)',
        desc: 'Since have been vsnary arenre reabe software enneern partne have been and visionary',
    },
    {
        imgUrl: 'assets/images/feature/03.png',
        imgAlt: 'feature',
        title: 'Preschool',
        age: '(2 – 3 years)',
        desc: 'Since have been vsnary arenre reabe software enneern partne have been and visionary',
    },
    {
        imgUrl: 'assets/images/feature/04.png',
        imgAlt: 'feature',
        title: 'Kindergarten',
        age: '(3 – 4 years)',
        desc: 'Since have been vsnary arenre reabe software enneern partne have been and visionary',
    },
]

const AboutTwo = () => {
    return (
        <section className="about-section pos-rel padding-top">
            <div className="top-shape-center">
                <img src="./assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="bottom-shape-left">
                <img src="./assets/css/img/bottom-shape.png" alt="css" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-30-none -mx-10">
                    {featureList.map((val, i) => (
                        <div className="col-sm-7 col-md-6 col-lg-5 col-xl-3" key={i}>
                            <div className="feature-item">
                                <div className="feature-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="ml--8" />
                                </div>
                                <div className="feature-content">
                                    <h5 className="title">{val.title}</h5>
                                    <span className="cate">{val.age}</span>
                                    <p>{val.desc}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <AboutThree />
        </section>
    )
}

export default AboutTwo