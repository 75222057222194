import React, { Fragment } from 'react'
import Footer from '../component/layout/footer'
import Header from '../component/layout/header'
import About from '../component/section/about'
import Banner from '../component/section/banner'
import Blog from '../component/section/blog'
import ClassSection from '../component/section/class'
import Client from '../component/section/client'
import Counter from '../component/section/counter'
import Facilities from '../component/section/facilities'
import Gallery from '../component/section/gallery'
import Schedule from '../component/section/schedule'
import Teacher from '../component/section/teacher'

const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <About />
            <Counter />
            <ClassSection />
            <Facilities />
            <Gallery />
            <Teacher />
            <Schedule />
            <Client />
            <Blog />
            <Footer />
        </Fragment>
    )
}

export default Home