import React from 'react';
import { Link } from 'react-router-dom';

const cate = "Meet Our Staffs";
const title = "Our Best Popular Teachers";


const teacherList = [
    {
        imgUrl: 'assets/images/teacher/teacher05.jpg',
        imgAlt: 'teacher',
        name: 'Jason Kovalsky',
        degi: 'Spanish Teacher',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-pinterest-p',
                siteLink: '#',
                className: 'pinterest',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
    {
        imgUrl: 'assets/images/teacher/teacher06.jpg',
        imgAlt: 'teacher',
        name: 'Bangile Yusha',
        degi: 'Spanish Teacher',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-google',
                siteLink: '#',
                className: 'google',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
    {
        imgUrl: 'assets/images/teacher/teacher07.jpg',
        imgAlt: 'teacher',
        name: 'Willium Kovalsky',
        degi: 'Spanish Teacher',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
    {
        imgUrl: 'assets/images/teacher/teacher08.jpg',
        imgAlt: 'teacher',
        name: 'Bini Zaara',
        degi: 'Spanish Teacher',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-pinterest-p',
                siteLink: '#',
                className: 'pinterest',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
]

const TeacherTwo = () => {
    return (
        <section className="teacher-section padding-top padding-bottom pos-rel">
            <div className="top-shape-center">
                <img src="./assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-30-none">
                    {teacherList.map((val, i) => (
                        <div className="col-lg-3 col-sm-6" key={i}>
                            <div className="teacher-item-2">
                                <div className="teacher-thumb">
                                    <div className="thumb">
                                        <Link to="/teacher-single">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><Link to="/teacher-single">{val.name}</Link></h5>
                                        <span>{val.degi}</span>
                                    </div>
                                </div>
                                <div className="teacher-content">
                                    <ul className="teacher-social justify-content-center">
                                        {val.socialList.map((val, i) => (
                                            <li key={i}>
                                                <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default TeacherTwo