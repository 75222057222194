import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CountDownTwo from '../component/sidebar/widget/countdown-2';

const title = "Coming Soon";

const ComingSoon = () => {
    return (
        <Fragment>
            <section className="coming-soon-section min-height-section bg_img" style={{backgroundImage: "url(/assets/images/error/error-bg.jpg)"}}>
                <div className="container">
                    <div className="coming-wrapper">
                        <div className="inner-logo">
                            <Link to="/"><img src="./assets/images/error/logo.png" alt="error" /></Link>
                        </div>
                        <CountDownTwo />
                        <h1 className="coming-soon-title">{title}</h1>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ComingSoon;