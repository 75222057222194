import React from 'react'
import { Link } from 'react-router-dom'

const PageHeader = ({title, curPage}) => {
    return (
        <section className="page-header bg_img" style={{backgroundImage: "url(/assets/images/banner/page-header.jpg)"}}>
            <div className="container">
                <div className="page-header-content">
                    <h1 className="title">{title}</h1>
                    <ul className="breadcrumb">
                        <li> <Link to="/">Home</Link> </li>
                        <li> {curPage} </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default PageHeader