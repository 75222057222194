import React from 'react';
import Slider from "react-slick";

import { Link } from 'react-router-dom';


const bannerList = [
    {
        title: 'Best Child Care Center - Kitton',
        desc: 'Prescholer for the apcation testing and enrollment process for publc and private schools the city of alo abu mal kita kores vai',
        btnText: 'Get Started Now',
        bgImg: 'assets/images/banner/slider/01.jpg',
        className: 'row',
        classNameTwo: 'banner-item-content',
    },
    {
        title: 'Best Child Care Center - Kitton',
        desc: 'Prescholer for the apcation testing and enrollment process for publc and private schools the city of alo abu mal kita kores vai',
        btnText: 'Get Started Now',
        bgImg: 'assets/images/banner/slider/02.jpg',
        className: 'row justify-content-center',
        classNameTwo: 'banner-item-content text-center',
    },
    {
        title: 'Best Child Care Center - Kitton',
        desc: 'Prescholer for the apcation testing and enrollment process for publc and private schools the city of alo abu mal kita kores vai',
        btnText: 'Get Started Now',
        bgImg: 'assets/images/banner/slider/03.jpg',
        className: 'row justify-content-end',
        classNameTwo: 'banner-item-content',
    },
]

const BannerThree = () => {
    const settings = {
        dots: false,
        infinite: true,
        loop: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <section className="banner-section style-slide">
            <Slider {...settings}>
                {bannerList.map((val, i) => (
                    <div className="banner-slider" key={i}>
                        <div className="banner-item bg_img" style={{backgroundImage: `url(${val.bgImg})`}}>
                            <div className="banner-item-inner">
                                <div className="container">
                                    <div className="banner-item-wrapper">
                                        <div className={val.className}>
                                            <div className="col-lg-6">
                                                <div className={val.classNameTwo}>
                                                    <h1>{val.title}</h1>
                                                    <p>{val.desc}</p>
                                                    <Link to="/login" className="custom-button"><span>{val.btnText}</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    )
}

export default BannerThree