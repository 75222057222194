import React from 'react';

const name = "Jhon Doe";
const desc = "Data release large ponted to wasing better than expcted pickup the euroz tolate Thursd and the euro edged upto slghtly towing are after the Grman recent political uncertainty in Germany has sofar";

const socialList = [
    {
        iconName: 'fab fa-facebook-f',
        className: 'facebook',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-pinterest-p',
        className: 'pinterest',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-vimeo-v',
        className: 'vimeo',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-behance',
        className: 'behance',
        siteLink: '#',
    },
]

const Author = () => {
    return (
        <div className="widget widget-author">
            <div className="widget-body">
                <div className="post-author">
                    <div className="thumb">
                        <img src="./assets/images/blog/author.jpg" alt="blog" />
                    </div>
                    <div className="content">
                        <h5 className="title">{name}</h5>
                        <p>{desc}</p>
                        <ul className="teacher-social">
                            {socialList.map((val, i) => (
                                <li key={i}>
                                    <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;