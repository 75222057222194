import React from 'react';

const title = "Archives";


const archiveList = [
    {
        text: 'January',
        year: '2022',
        link: '#',
    },
    {
        text: 'February',
        year: '2022',
        link: '#',
    },
    {
        text: 'May',
        year: '2022',
        link: '#',
    },
    {
        text: 'June',
        year: '2022',
        link: '#',
    },
    {
        text: 'February',
        year: '2020',
        link: '#',
    },
    {
        text: 'January',
        year: '2020',
        link: '#',
    },
    {
        text: 'March',
        year: '2020',
        link: '#',
    },
    {
        text: 'Joomla',
        year: '2020',
        link: '#',
    },
]

const Archive = () => {
    return (
        <div className="widget widget-archive">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul>
                    {archiveList.map((val, i) => (
                        <li key={i}>
                            <a href={val.link}><span><i className="fas fa-angle-double-right"></i>{val.text}</span><span>{val.year}</span></a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Archive;