import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Events from '../component/section/events';

const EventsPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Events Page'} curPage={'Evens'}  />
            <Events />
            <Footer />
        </Fragment>
    )
}

export default EventsPage;