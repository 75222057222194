import React from 'react'
import { Link } from 'react-router-dom'

const cate = "Our School Classes";
const title = "Most Popular School Classes";
const btnText = "Get Started Now";

const classList = [
    {
        imgUrl: 'assets/images/class/class04.jpg',
        authorImg: 'assets/images/class/teacher01.jpg',
        imgAlt: 'class thumb',
        title: 'Music And Performance',
        name: 'Joly Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        imgUrl: 'assets/images/class/class05.jpg',
        authorImg: 'assets/images/class/teacher02.jpg',
        imgAlt: 'class thumb',
        title: 'Religion And History',
        name: 'Alex Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        imgUrl: 'assets/images/class/class06.jpg',
        authorImg: 'assets/images/class/teacher03.jpg',
        imgAlt: 'class thumb',
        title: 'Art And Color Management',
        name: 'Jhon Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        imgUrl: 'assets/images/class/class07.jpg',
        authorImg: 'assets/images/class/teacher01.jpg',
        imgAlt: 'class thumb',
        title: 'Music And Performance',
        name: 'Joly Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        imgUrl: 'assets/images/class/class08.jpg',
        authorImg: 'assets/images/class/teacher02.jpg',
        imgAlt: 'class thumb',
        title: 'Religion And History',
        name: 'Alex Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
    {
        imgUrl: 'assets/images/class/class09.jpg',
        authorImg: 'assets/images/class/teacher03.jpg',
        imgAlt: 'class thumb',
        title: 'Art And Color Management',
        name: 'Jhon Smith',
        profile: 'View Profile',
        price: '$12.96',
        valided: 'Per Month',
        rotingList: [
            {
                title: '24 - 30',
                info: 'Class Size'
            },
            {
                title: '09:30 -12:00',
                info: 'Class Time'
            },
            {
                title: '06 - 08',
                info: 'Years Old'
            },
        ]
    },
]

const ClassSectionTwo = () => {
    return (
        <section className="class-section padding-top padding-bottom pos-rel">
            <div className="top-shape-center">
                <img src="assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-30-none">
                    {classList.map((val, i) => (
                        <div className="col-xl-4 col-md-6" key={i}>
                            <div className="class-item">
                                <div className="class-thumb">
                                    <Link to="/class-schedule"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                </div>
                                <div className="class-content">
                                    <h5 className="title">
                                        <Link to="/class-schedule">{val.title}</Link>
                                    </h5>
                                    <div className="class-meta">
                                        <div className="class-author">
                                            <div className="thumb">
                                                <Link to="/teacher-single"><img src={`${val.authorImg}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="content">
                                                <h6 className="subtitle"><Link to="/teacher-single">{val.name}</Link></h6>
                                                <Link to="/teacher-single" className="info">{val.profile}</Link>
                                            </div>
                                        </div>
                                        <div className="cost-area">
                                            <h6 className="subtitle">{val.price}</h6>
                                            <span className="info">{val.valided}</span>
                                        </div>
                                    </div>
                                    <div className="schedule-area">
                                        {val.rotingList.map((val, i) => (
                                            <div className="schedule-item" key={i}>
                                                <h6 className="sub-title">{val.title}</h6>
                                                <span className="info">{val.info}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </div>
                <div className="load-more">
                    <Link to="/classes" className="custom-button"><span>{btnText}</span></Link>
                </div>
            </div>
        </section>
    )
}

export default ClassSectionTwo