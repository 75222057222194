import React from 'react';

const title = "Course Features";

const quesList = [
    {
        iconName: 'fas fa-bell',
        ques: 'Start Date',
        ans: '24 march 2022',
        className: 'ques cl-i-one',
    },
    {
        iconName: 'fas fa-user',
        ques: 'Years old',
        ans: '04 - 06 years',
        className: 'ques cl-i-two',
    },
    {
        iconName: 'fas fa-sitemap',
        ques: 'Class Size',
        ans: '20 - 30 kids',
        className: 'ques cl-i-three',
    },
    {
        iconName: 'fas fa-bus',
        ques: 'Carry time',
        ans: '05 hours / 06 days',
        className: 'ques cl-i-four',
    },
    {
        iconName: 'fas fa-address-book',
        ques: 'Class Duration',
        ans: '10 - 12 month',
        className: 'ques cl-i-five',
    },
    {
        iconName: 'fas fa-clock',
        ques: 'Class time',
        ans: '09:00am - 12:00pm',
        className: 'ques cl-i-six',
    },
    {
        iconName: 'fas fa-coins',
        ques: 'Tution Fee',
        ans: '$2500.00',
        className: 'ques cl-i-eight',
    },
]

const CourseFeatures = () => {
    return (
        <div className="widget widget-feature">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul>
                    {quesList.map((val, i) => (
                        <li key={i}>
                            <div className={val.className}><i className={val.iconName}></i>{val.ques}</div>
                            <div className="ans">{val.ans}</div>
                        </li>
                    ))}
                    <li>
                        <div className="ques cl-i-seven"><i className="fas fa-star"></i>Rating</div>
                        <div className="ans cl-i-seven">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CourseFeatures