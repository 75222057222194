import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Pagination from '../component/sidebar/widget/pagination';


const blogList = [
    {
        imgUrl: 'assets/images/blog/blog01.jpg',
        imgAlt: 'blog',
        title: 'How Kids make sense of Life Experiences',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog02.jpg',
        imgAlt: 'blog',
        title: 'Why do aerobicay fit children have better?',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog03.jpg',
        imgAlt: 'blog',
        title: 'Why do aerobically have better Math Skills',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog04.jpg',
        imgAlt: 'blog',
        title: 'How Kids make sense of Life Experiences',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog05.jpg',
        imgAlt: 'blog',
        title: 'Why do aerobicay fit children have better?',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog06.jpg',
        imgAlt: 'blog',
        title: 'Why do aerobically have better Math Skills',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog07.jpg',
        imgAlt: 'blog',
        title: 'How Kids make sense of Life Experiences',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog08.jpg',
        imgAlt: 'blog',
        title: 'Why do aerobicay fit children have better?',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog09.jpg',
        imgAlt: 'blog',
        title: 'Why do aerobically have better Math Skills',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        commentCount: '2',
    },
]

const BlogGrid = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Recent Blog Posts'} curPage={'Blog'} />
            <div className="blog-section padding-top padding-bottom">
                <div className="container">
                    <div className="row mb-30-none justify-content-center">
                        {blogList.map((val, i) => (
                            <div className="col-xl-4 col-md-6" key={i}>
                                <div className="post-item">
                                    <div className="post-thumb">
                                        <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                    </div>
                                    <div className="post-content">
                                        <div className="post-top">
                                            <h5 className="title"><Link to="/blog-single">{val.title}</Link>
                                            </h5>
                                            <div className="post-meta cate">
                                                <a href="#"><i className="far fa-calendar"></i> {val.date}</a>
                                                <a href="#"><i className="fas fa-user"></i> {val.author}</a>
                                            </div>
                                            <p>{val.desc}</p>
                                        </div>
                                        <div className="post-bottom">
                                            <Link to="/blog-single" className="read">Read More</Link>
                                            <a href="#" className="comments"><i className="far fa-comments"></i> <span className="comment-number">{val.commentCount}</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination />
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default BlogGrid