import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import AboutFour from '../component/section/about-4';
import AboutFive from '../component/section/about-5';
import CounterTwo from '../component/section/counter-2';
import TeacherTwo from '../component/section/teacher-2';

const AboutPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'About Kittons'} curPage={'About us'} />
            <AboutFour />
            <AboutFive />
            <CounterTwo />
            <TeacherTwo />
            <Footer />
        </Fragment>
    )
}

export default AboutPage;