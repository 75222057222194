import React from 'react';

const cate = "Classes Timetable";
const title = "Our Classes Timetable";

const scheduleList = [
    {
        bgImg: 'assets/images/schedule/monday.png',
        infoList: [
            {
                title: 'Painting',
                time: '07:30am - 08:30am',
                className: 'title painting',
            },
            {
                title: 'English',
                time: '07:30am - 08:30am',
                className: 'title',
            },
            {
                title: 'Power Fitnes',
                time: '07:30am - 08:30am',
                className: 'title fitness',
            },
        ],
    },
    {
        bgImg: 'assets/images/schedule/tuesday.png',
        infoList: [
            {
                title: 'Painting',
                time: '07:30am - 08:30am',
                className: 'title',
            },
            {
                title: 'English',
                time: '07:30am - 08:30am',
                className: 'title fitness',
            },
            {
                title: 'Power Fitnes',
                time: '07:30am - 08:30am',
                className: 'title painting',
            },
        ],
    },
    {
        bgImg: 'assets/images/schedule/wednesday.png',
        infoList: [
            {
                title: 'Painting',
                time: '07:30am - 08:30am',
                className: 'title fitness',
            },
            {
                title: 'English',
                time: '07:30am - 08:30am',
                className: 'title painting',
            },
            {
                title: 'Power Fitnes',
                time: '07:30am - 08:30am',
                className: 'title',
            },
        ],
    },
    {
        bgImg: 'assets/images/schedule/thursday.png',
        infoList: [
            {
                title: 'Painting',
                time: '07:30am - 08:30am',
                className: 'title fitness',
            },
            {
                title: 'English',
                time: '07:30am - 08:30am',
                className: 'title',
            },
            {
                title: 'Power Fitnes',
                time: '07:30am - 08:30am',
                className: 'title painting',
            },
        ],
    },
    {
        bgImg: 'assets/images/schedule/friday.png',
        infoList: [
            {
                title: 'Painting',
                time: '07:30am - 08:30am',
                className: 'title',
            },
            {
                title: 'English',
                time: '07:30am - 08:30am',
                className: 'title painting',
            },
            {
                title: 'Power Fitnes',
                time: '07:30am - 08:30am',
                className: 'title fitness',
            },
        ],
    },
    {
        bgImg: 'assets/images/schedule/saturday.png',
        infoList: [
            {
                title: 'Painting',
                time: '07:30am - 08:30am',
                className: 'title painting',
            },
            {
                title: 'English',
                time: '07:30am - 08:30am',
                className: 'title fitness',
            },
            {
                title: 'Power Fitnes',
                time: '07:30am - 08:30am',
                className: 'title',
            },
        ],
    },
]

const Schedule = () => {
    return (
        <section className="schedule-section padding-top padding-bottom">
            <div className="top-shape-center">
                <img src="assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb--20--50 justify-content-center">
                    {scheduleList.map((val, i) => (
                        <div className="col-xl-4 col-md-6" key={i}>
                            <div className="schedule-item-2 bg_img bg_contain" style={{backgroundImage: `url(${val.bgImg})`}}>
                                <ul>
                                    {val.infoList.map((val, i) => (
                                        <li key={i}>
                                            <h6 className={val.className}>{val.title}</h6>
                                            <span className="time">{val.time}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Schedule