import React, { Fragment } from 'react'
import Footer from '../component/layout/footer'
import Header from '../component/layout/header'
import AboutThree from '../component/section/about-3'
import BannerThree from '../component/section/banner-3'
import Blog from '../component/section/blog'
import ClassSection from '../component/section/class'
import Client from '../component/section/client'
import CounterTwo from '../component/section/counter-2'
import Schedule from '../component/section/schedule'
import Teacher from '../component/section/teacher'

const HomeThree = () => {
    return (
        <Fragment>
            <Header />
            <BannerThree />
            <AboutThree />
            <CounterTwo />
            <ClassSection />
            <Teacher />
            <Schedule />
            <Client />
            <Blog />
            <Footer />
        </Fragment>
    )
}

export default HomeThree