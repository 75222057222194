import React from 'react';

const cate = 'School Facilities';
const title = 'EveryDay Care For Your Children.';
const desc = 'Dolor ame consectetur elite eusmod tempor dunt aliqua utas enim veniam minim veniam quis nostrud ullamco laboris nisiut aliquip';


const facilityList = [
    {
        imgUrl: 'assets/images/facilities/facility1.png',
        imgAlt: 'facilities',
        title: 'Clean Playgrounds',
    },
    {
        imgUrl: 'assets/images/facilities/facility2.png',
        imgAlt: 'facilities',
        title: 'Private School Bus',
    },
    {
        imgUrl: 'assets/images/facilities/facility3.png',
        imgAlt: 'facilities',
        title: 'Modern Canteen',
    },
    {
        imgUrl: 'assets/images/facilities/facility4.png',
        imgAlt: 'facilities',
        title: 'Colorful Classes',
    },
    {
        imgUrl: 'assets/images/facilities/facility5.png',
        imgAlt: 'facilities',
        title: 'Positive Learning',
    },
    {
        imgUrl: 'assets/images/facilities/facility6.png',
        imgAlt: 'facilities',
        title: 'Fun With Games',
    },
]


const Facilities = () => {
    return (
        <section className="facilities-section">
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-lg-7 col-xl-6 px-0">
                        <div className="facility-area padding-bottom padding-top bg_img" style={{backgroundImage: "url(/assets/images/facilities/facility-bg2.jpg)"}}>
                            <div className="facility-container">
                                <div className="cl-white section-header left-style">
                                    <span className="cate">{cate}</span>
                                    <h3 className="title">{title}</h3>
                                    <p>{desc}</p>
                                </div>
                                <div className="facility-wrapper">
                                    {facilityList.map((val, i) => (
                                        <div className="facility-item" key={i}>
                                            <div className="facility-inner">
                                                <div className="facility-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <h6 className="title">{val.title}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-6 px-0 bg_img" style={{backgroundImage: "url(/assets/images/facilities/facility-bg.jpg)"}}></div>
                </div>
            </div>
        </section>
    )
}

export default Facilities