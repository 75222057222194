import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Progress from '../component/sidebar/widget/progress';

const name = "Bini Zaara";
const degi = "Pre-K Teacher";
const subTitle = "Personal Statement";
const descOne = "Enthusias expedite client-focused growth strategies whereas client-centered infrastructur ntrinsicl grow optimal e-tailers rather than effective information Collabora optimize partnersh and frictionles deliverables";
const descTwo = "Enthusias expedite client-focused growth strategies whereas client-centered infrastructur ntrinsicl grow optimal e-tailers rather than effective information Collabora optimize partnersh and frictionles deliverables";

const skTitle = "Personal Skill";
const awTitle = "Recognitions Award";


const memInfoLisst = [
    {
        leftText: 'Adress',
        rightText: 'Suite 02 and 07 Melbourne, Australia',
    },
    {
        leftText: 'Email',
        rightText: 'emileelogan@gamil.com',
    },
    {
        leftText: 'Phone',
        rightText: '+021 548 736 982 ,01236985',
    },
    {
        leftText: 'website',
        rightText: 'www.adminedukon.com',
    },
    {
        leftText: 'Interview',
        rightText: 'Read The Interview',
    },
]

const memSocialList = [
    {
        iconName: 'fab fa-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-instagram',
        className: 'instagram',
        siteLink: '#',
    },
    {
        iconName: 'fas fa-basketball-ball',
        className: 'basketball',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-vimeo-v',
        className: 'vimeo',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-behance',
        className: 'behance',
        siteLink: '#',
    },
]

const skillList = [
    {
        percentage: '80',
        text: 'English'
    },
    {
        percentage: '70',
        text: 'Hindi'
    },
    {
        percentage: '90',
        text: 'Bangla'
    },
]

const awardList = [
    {
        imgUrl: 'assets/images/teacher/01.png',
        imgAlt: 'instructor',
        text: 'Award 2018',
    },
    {
        imgUrl: 'assets/images/teacher/02.png',
        imgAlt: 'instructor',
        text: 'Award 2019',
    },
    {
        imgUrl: 'assets/images/teacher/03.png',
        imgAlt: 'instructor',
        text: 'Award 2020',
    },
]

const TeacherSingle = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Bini Zaara'} curPage={'Bini Zaara'} />
            <section className="speaker-single-section padding-top padding-bottom">
                <div className="container">
                    <div className="teacher-wrapper">
                        <div className="teacher-single-top">
                            <div className="teacher-single-item d-flex flex-wrap justify-content-between">
                                <div className="teacher-single-thumb">
                                    <img src="assets/images/teacher/teacher-single.jpg" alt="teacher" />
                                </div>
                                <div className="teacher-single-content">
                                    <h4 className="title">{name}</h4>
                                    <p>{degi}</p>
                                    <p className="header-p">{descOne}</p>
                                    <h6 className="subtitle">{subTitle}</h6>
                                    <p className="header-p">{descTwo}</p>
                                    <ul>
                                        {memInfoLisst.map((val, i) => (
                                            <li className="d-flex flex-wrap justify-content-start" key={i}>
                                                <span className="list-name">{val.leftText}</span>
                                                <span className="list-attr">{val.rightText}</span>
                                            </li>
                                        ))}
                                        <li className="d-flex flex-wrap justify-content-start">
                                            <span className="list-name">Follow Us</span>
                                            <ul className="list-attr d-flex flex-wrap justify-content-start">
                                                {memSocialList.map((val, i) => (
                                                    <li key={i}>
                                                        <a className={val.className} href={val.siteLink}><i className={val.iconName}></i></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row teacher-single-bottom mb--50">
                            <div className="col-lg-6 pb-20 d-flex flex-wrap justify-content-lg-start justify-content-between">
                                <h6 className="w-100 subtitle">{skTitle}</h6>
                                {skillList.map((val, i) => (
                                    <div className="text-center skill-item" key={i}>
                                        <Progress strokeWidth={8} percentage={val.percentage} />
                                        <p>{val.text}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-6 pb-20 d-flex flex-wrap justify-content-lg-start justify-content-between">
                                <h6 className="w-100 subtitle">{awTitle}</h6>
                                {awardList.map((val, i) => (
                                    <div className="skill-item text-center" key={i}>
                                        <div className="skill-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <p>{val.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default TeacherSingle;