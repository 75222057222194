import React, { Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const shopingTitle = "Shopping cart";

const infoList = [
    {
        iconName: 'far fa-clock',
        text: '9:30am - 6:30pm Mon - Sun',
    },
    {
        iconName: 'fas fa-phone-alt',
        text: '+800-123-4567 6587',
    },
    {
        iconName: 'fas fa-map-marker-alt',
        text: 'Beverley, New York 224 US',
    },
]

const socialList = [
    {
        iconName: 'fab fa-facebook-messenger',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-vimeo-v',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-skype',
        siteLink: '#',
    },
    {
        iconName: 'fas fa-wifi',
        siteLink: '#',
    },
]

const cartList = [
    {
        imgUrl: 'assets/images/shop/shop01.png',
        imgAlt: 'product',
        title: 'Color Pencil',
        oldPrice: '$120.00',
        newPrice: '$80.00',
    },
    {
        imgUrl: 'assets/images/shop/shop02.png',
        imgAlt: 'product',
        title: 'Water Pot',
        oldPrice: '$120.00',
        newPrice: '$80.00',
    },
    {
        imgUrl: 'assets/images/shop/shop03.png',
        imgAlt: 'product',
        title: 'Art Paper',
        oldPrice: '$120.00',
        newPrice: '$80.00',
    },
    {
        imgUrl: 'assets/images/shop/shop04.png',
        imgAlt: 'product',
        title: 'Stop Watch',
        oldPrice: '$120.00',
        newPrice: '$80.00',
    },
    {
        imgUrl: 'assets/images/shop/shop05.png',
        imgAlt: 'product',
        title: 'Comics Book',
        oldPrice: '$120.00',
        newPrice: '$80.00',
    },
]

const Header = () => {
    const [searchToggle, setSearchToggle] = useState(false);
    const [cartToggle, setCartToggle] = useState(false);
    const [headerTopToggle, setHeaderTopToggle] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);
    const [headerFiexd, setHeaderFiexd] = useState(false);

    window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    return (
        <Fragment>
            <header className={`${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
                <div className={`header-top ${headerTopToggle ? "open" : ""}`}>
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="left">
                                {infoList.map((val, i) => (
                                    <li key={i}>
                                        <i className={val.iconName}></i> {val.text}
                                    </li>
                                ))}
                            </ul>
                            <ul className="social-icons">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.siteLink}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <Link to="/">
                                    <img src="assets/images/logo/logo.png" alt="logo" />
                                </Link>
                            </div>
                            <div className="menu-area">
                                <ul className={`menu ${menuToggle ? "active" : ""}`}>
                                    <li>
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"><i className="fas fa-home"></i>Home</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/">Home One</NavLink></li>
                                            <li><NavLink to="/index-2">Home Two</NavLink></li>
                                            <li><NavLink to="/index-3">Home Three</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"><i className="fas fa-book"></i>Classes</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/classes">Classes</NavLink></li>
                                            <li><NavLink to="/class-single">Class Single</NavLink></li>
                                            <li><NavLink to="/class-schedule">Class Schedule</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"><i className="fas fa-layer-group"></i>Pages</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/about">About</NavLink></li>
                                            <li><NavLink to="/teacher">Our Teacher</NavLink></li>
                                            <li><NavLink to="/teacher-single">Teacher Single</NavLink></li>
                                            <li><NavLink to="/events">Our Events</NavLink></li>
                                            <li><NavLink to="/events-single">Events Single</NavLink></li>
                                            <li><NavLink to="/faqs">Faqs</NavLink></li>
                                            <li><NavLink to="/contact">Contact</NavLink></li>
                                            <li><NavLink to="/login">Sign In</NavLink></li>
                                            <li><NavLink to="/registration">Sign Up</NavLink></li>
                                            <li><NavLink to="/frgetpass">Forget Pass</NavLink></li>
                                            <li><NavLink to="/coming-soon">Coming Soon</NavLink></li>
                                            <li><NavLink to="/error-page">404 Error</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"><i className="fas fa-images"></i>Gallery</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/gallery">Gallery One</NavLink></li>
                                            <li><NavLink to="/gallery-2">Gallery Two</NavLink></li>
                                            <li><NavLink to="/gallery-3">Gallery Three</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"><i className="fas fa-pencil-alt"></i>Blog</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/blog-grid">Blog Grid</NavLink></li>
                                            <li><NavLink to="/blog-classic">Blog Classic</NavLink></li>
                                            <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0"><i className="fas fa-shopping-cart"></i>Shop</a>
                                        <ul className="submenu dropdown-menu">
                                            <li><NavLink to="/shop">Our Shop</NavLink></li>
                                            <li><NavLink to="/shop-single">Shop Single</NavLink></li>
                                            <li><NavLink to="/cart">Cart</NavLink></li>
                                        </ul>
                                    </li>
                                </ul>

                                <div className="search-button" onClick={() => setSearchToggle(!searchToggle)}>
                                    <a href="#">
                                        <i className="fas fa-search"></i>
                                    </a>
                                </div>

                                <div className="cart-button">
                                    <a href="#" onClick={() => setCartToggle(!cartToggle)}>
                                        <span className="cart-amount">05</span>
                                        <i className="fas fa-shopping-basket"></i>
                                    </a>
                                </div>

                                <div className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`} onClick={() => setMenuToggle(!menuToggle)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                                <div className="ellepsis-bar d-lg-none" onClick={() => setHeaderTopToggle(!headerTopToggle)}>
                                    <i className="fas fa-ellipsis-h"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={`header-form ${searchToggle ? "active" : ""}`}>
                <div className="bg-lay" onClick={() => setSearchToggle(!searchToggle)}>
                    <div className="cross"><i className="fas fa-times"></i></div>
                </div>
                <form className="form-container">
                    <input type="text" placeholder="Input Your Search" name="name" />
                    <button type="submit">Search</button>
                </form>
            </div>
            
            <div className={`overlay ${cartToggle ? "active" : ""}`}  onClick={() => setCartToggle(!cartToggle)}></div>

            <div className={`cart-sidebar-area ${cartToggle ? "active" : ""}`}>
                <div className="top-content">
                    <Link to="/" className="logo">
                        <img src="assets/images/logo/logo.png" alt="logo" />
                    </Link>
                    <span className="side-sidebar-close-btn" onClick={() => setCartToggle(!cartToggle)}><i className="fas fa-times"></i></span>
                </div>
                <div className="bottom-content">
                    <div className="cart-products">
                        <h4 className="title">{shopingTitle}</h4>
                        {cartList.map((val, i) => (
                            <div className="single-product-item" key={i}>
                                <div className="thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <div className="price"><span className="pprice">{val.newPrice}</span> <del className="dprice">{val.oldPrice}</del></div>
                                    <a href="#" className="remove-cart">Remove</a>
                                </div>
                            </div>
                        ))}
                        <div className="btn-wrapper text-center">
                            <Link to="/cart" className="custom-button"><span>Checkout</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Header