import React from 'react';
import { Link } from 'react-router-dom';

const title = "Most Popular Post";

const postList = [
    {
        imgUrl: 'assets/images/blog/side1.jpg',
        imgAlt: 'blog thumb',
        title: 'How Kids make sense of Life Experiences',
        date: 'April 08,2022',
    },
    {
        imgUrl: 'assets/images/blog/side2.jpg',
        imgAlt: 'blog thumb',
        title: 'Why do aerobically have better Math Skills',
        date: 'April 08,2022',
    },
    {
        imgUrl: 'assets/images/blog/side3.jpg',
        imgAlt: 'blog thumb',
        title: 'How Kids make sense of Life Experiences',
        date: 'April 08,2022',
    },
    {
        imgUrl: 'assets/images/blog/side4.jpg',
        imgAlt: 'blog thumb',
        title: 'Remo Suppor Center What For Semiconductor Provider',
        date: 'April 08,2022',
    },
]

const PopularPost = () => {
    return (
        <div className="widget widget-post">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul>
                    {postList.map((val, i) => (
                        <li key={i}>
                            <div className="thumb">
                                <Link to="/blog-single">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </Link>
                            </div>
                            <div className="content">
                                <Link to="/blog-single">{val.title}</Link>
                                <span>{val.date}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopularPost