import React from 'react';
import { Link } from 'react-router-dom';

const title = "Become A Teacher";
const desc = "If you want to join with us as a trainer to share you knowledge & professional experiance, We will say you welcome as our trainer family";
const btnText = "Get Started Now";

const CallTwo = () => {
    return (
        <section className="call-in-action call-overlay bg_img padding-bottom padding-top" style={{backgroundImage: "url(/assets/images/bg/call-bg.jpg)"}}>
            <div className="container">
                <div className="call-wrapper">
                    <div className="section-header mb-olpo">
                        <h3 className="title">{title}</h3>
                        <p>{desc}</p>
                    </div>
                    <div className="text-center">
                        <Link to="/login" className="custom-button"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallTwo