import React from 'react';
import Slider from "react-slick";
import Rating from '../sidebar/widget/rating';

const cate = "Parents Say";
const title = "What Students Parents Say";

const clientList = [
    {
        imgUrl: 'assets/images/client/client1.png',
        authorLogoImg: 'assets/images/client/logo1.png',
        imgAlt: 'client',
        name: 'Angel Witicker',
        degi: 'UX Designer',
        desc: 'Rapidiously buildcollaboration anden deas sharing viaing bleeding and edge nterfaces Energstcally plagiarize team anbuilding and paradgmis whereas goingi forward process mproveents and monetze fully tested ergstcally plariarize team whereas goingi forward process an services whereas going forward process',
    },
    {
        imgUrl: 'assets/images/client/client2.png',
        authorLogoImg: 'assets/images/client/logo2.png',
        imgAlt: 'client',
        name: 'Angel Witicker',
        degi: 'UX Designer',
        desc: 'Rapidiously buildcollaboration anden deas sharing viaing bleeding and edge nterfaces Energstcally plagiarize team anbuilding and paradgmis whereas goingi forward process mproveents and monetze fully tested ergstcally plariarize team whereas goingi forward process an services whereas going forward process',
    },
    {
        imgUrl: 'assets/images/client/client3.png',
        authorLogoImg: 'assets/images/client/logo3.png',
        imgAlt: 'client',
        name: 'Angel Witicker',
        degi: 'UX Designer',
        desc: 'Rapidiously buildcollaboration anden deas sharing viaing bleeding and edge nterfaces Energstcally plagiarize team anbuilding and paradgmis whereas goingi forward process mproveents and monetze fully tested ergstcally plariarize team whereas goingi forward process an services whereas going forward process',
    },
]


const Client = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
        ],
    };
    return (
        <section className="client-section padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/client/client-bg.jpg)"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header cl-white">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="oh">
                    <Slider {...settings}>
                        {clientList.map((val, i) => (
                            <div className="client-item w-auto mx-3" key={i}>
                                <div className="client-header">
                                    <div className="author">
                                        <div className="thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">{val.name}</h6>
                                            <span>{val.degi}</span>
                                        </div>
                                    </div>
                                    <div className="company">
                                        <img src={`${val.authorLogoImg}`} alt={`${val.imgAlt}`} />
                                    </div>
                                </div>
                                <div className="client-body">
                                    <p>{val.desc}</p>
                                    <Rating />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Client