import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

const title = "Register Now";
const subTitle = "Register With Social Media";

const socialList = [
    {
        iconName: 'fab fa-facebook-f',
        className: 'facebook',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-linkedin-in',
        className: 'linkedin',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-instagram',
        className: 'instagram',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-pinterest',
        className: 'pinterest',
        siteLink: '#',
    },
]

const Registration = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Kittons Register Now'} curPage={'Register'} />
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form">
                            <div className="form-group">
                                <input type="text" placeholder="User Name" name="username" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Email" name="email" />
                            </div>
                            <div className="form-group">
                                <input type="password" placeholder="Password" name="password" />
                            </div>
                            <div className="form-group">
                                <input type="password" placeholder="Confirm Password" name="password" />
                            </div>
                            <div className="form-group">
                                <button className="d-block custom-button"><span>Get Started Now</span></button>
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                            <span className="or"><span>or</span></span>
                            <h5 className="subtitle">{subTitle}</h5>
                            <ul className="social-icons justify-content-center">
                                {socialList.map((val, i) => (
                                    <li key={i}>
                                        <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Registration;