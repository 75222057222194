import React from 'react';


const contactMapList = [
    {
        imgUrl: 'assets/images/contact/01.png',
        imgAlt: 'contact',
        title: 'Class Sessions',
        infoList: [
            {
                text: 'Sunday - Monday',
            },
            {
                text: '08:00 am – 11:00 am (Fri Closed)',
            },
        ],
    },
    {
        imgUrl: 'assets/images/contact/02.png',
        imgAlt: 'contact',
        title: 'kittons Address',
        infoList: [
            {
                text: '123 White Amphitheatre Kinway,',
            },
            {
                text: 'Mountain, CA 12345, USA',
            },
        ],
    },
    {
        imgUrl: 'assets/images/contact/03.png',
        imgAlt: 'contact',
        title: 'Openning Hours',
        infoList: [
            {
                text: 'Sunday - Monday',
            },
            {
                text: '08.00 am – 05.00 pm',
            },
        ],
    },
    {
        imgUrl: 'assets/images/contact/04.png',
        imgAlt: 'contact',
        title: 'Phone & E-mail',
        infoList: [
            {
                text: '+123 456 78910, 0265478',
            },
            {
                text: 'enroll@kittons.com',
            },
        ],
    },
]

const GoogleMap = () => {
    return (
        <div className="map-section pos-rel">
            <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13004082.928417291!2d-104.65713107818928!3d37.275578278180674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1655979749150!5m2!1sen!2sbd" loading="lazy"></iframe>
            </div>
            <div className="contact-area padding-bottom padding-top pt-lg-0 pb-lg-0">
                <div className="container">
                    <div className="contact-wrapper">
                        {contactMapList.map((val, i) => (
                            <div className="contact-item" key={i}>
                                <div className="contact-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                                <div className="contact-content">
                                    <h6 className="title">{val.title}</h6>
                                    <ul>
                                        {val.infoList.map((val, i) => (
                                            <li key={i}>{val.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap