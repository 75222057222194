import React from 'react';
import { Link } from 'react-router-dom';

const subTitle = "It’s Perfect Time to Enroll";
const Title = "How to Enroll Your Chilld to A Class?";
const btnText = "Get Started Now";

const Call = () => {
    return (
        <section className="call-in-action padding-top padding-bottom theme-overlay bg_img" style={{backgroundImage: "url(/assets/images/bg/call-bg.jpg)"}}>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-8">
                        <div className="section-header left-style cl-white mb-olpo mb-md-0">
                            <h3 className="mb-3">{subTitle}</h3>
                            <h3 className="title">{Title}</h3>
                        </div>
                    </div>
                    <div className="col-md-4 text-md-right">
                        <Link to="/contact" className="custom-button theme-one"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Call