import React from 'react';

const cate = "About Our kitton";
const title = "Special Attention For Every Child";
const desc = "Dolor sit amet consectetur elitery eiusmod tempor dunt aliqua utas enim veniam tempore quis sed psum nostrud ipsume amet consectetur adipisicing elit sedom eiusmod tempor incididunt ut labore";


const AboutFive = () => {
    return (
        <section className="about-section pos-rel padding-bottom padding-top oh">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="rtl d-none d-lg-block pr-4">
                            <img src="./assets/images/about/about03.png" alt="about" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header left-style mb-olpo">
                                <span className="cate">{cate}</span>
                                <h3 className="title">{title}</h3>
                                <p>{desc}</p>
                            </div>
                            <div className="faq-wrapper mb--10">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accOne">
                                            <button className="accordion-button title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span className="right-icon"></span>
                                                Programs for Everyone
                                            </button>
                                        </div>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="accOne" data-bs-parent="#accordionExample">
                                            <div className="faq-content">
                                                <p>Since 2007 we have been visionary and areliable software enginering partner for worldclass brands what are bouque digital transformation are awesome theme consultancy.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accTwo">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <span className="right-icon"></span>
                                                Professional Teachers
                                            </button>
                                        </div>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="accTwo" data-bs-parent="#accordionExample">
                                            <div className="faq-content">
                                                <p>Since 2007 we have been visionary and areliable software enginering partner for worldclass brands what are bouque digital transformation are awesome theme consultancy.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accThree">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <span className="right-icon"></span>
                                                Professional Teachers
                                            </button>
                                        </div>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="faq-content">
                                                <p>Since 2007 we have been visionary and areliable software enginering partner for worldclass brands what are bouque digital transformation are awesome theme consultancy.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutFive;