import React from 'react';
import CountUp from 'react-countup';

const counterList = [
    {
        imgUrl: 'assets/images/counter/counter1.png',
        imgAlt: 'counter',
        countNumber: '830',
        text: 'Students Enrolled',
    },
    {
        imgUrl: 'assets/images/counter/counter2.png',
        imgAlt: 'counter',
        countNumber: '26',
        text: 'Certified Trainer',
    },
    {
        imgUrl: 'assets/images/counter/counter3.png',
        imgAlt: 'counter',
        countNumber: '100',
        text: 'Yearly Success Rate',
    },
]

const Counter = () => {
    return (
        <div className="counter-section padding-top padding-bottom bg_img" style={{backgroundImage: "url(/assets/images/counter/counter-bg.jpg)"}}>
            <div className="container">
                <div className="row justify-content-center mb-30-none">
                    {counterList.map((val, i) => (
                        <div className="col-lg-4 col-md-6 col-sm-10" key={i}>
                            <div className="counter-item">
                                <div className="counter-thumb">
                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                </div>
                                <div className="counter-content">
                                    <div className="counter-header">
                                        <h2 className="title"><CountUp end={val.countNumber} /></h2>
                                    </div>
                                    <span className="cate">{val.text}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Counter