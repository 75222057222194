import React, { Fragment } from 'react'
import Footer from '../component/layout/footer'
import Header from '../component/layout/header'
import AboutTwo from '../component/section/about-2'
import BannerTwo from '../component/section/banner-2'
import Blog from '../component/section/blog'
import Call from '../component/section/call'
import CallTwo from '../component/section/call-2'
import ClassSectionTwo from '../component/section/class-2'
import CounterTwo from '../component/section/counter-2'
import TeacherTwo from '../component/section/teacher-2'
import GoogleMap from '../component/sidebar/widget/google-map'

const HomeTwo = () => {
    return (
        <Fragment>
            <Header />
            <BannerTwo />
            <AboutTwo />
            <CounterTwo />
            <ClassSectionTwo />
            <Call />
            <TeacherTwo />
            <CallTwo />
            <Blog />
            <GoogleMap />
            <Footer />
        </Fragment>
    )
}

export default HomeTwo