import React from 'react';
import { Link } from 'react-router-dom';

const cate = "Meet Our Stuffy";
const title = "Our Best Popular Teachers";

const teacherList = [
    {
        imgUrl: 'assets/images/teacher/teacher01.jpg',
        imgAlt: 'teacher',
        name: 'Steve Jobs',
        degi: 'Spanish Teacher',
        desc: 'Samet consectetuer apiscing elitsed diam nonumy nibh euismod nciduns awesome team mumber',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-pinterest-p',
                siteLink: '#',
                className: 'pinterest',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
    {
        imgUrl: 'assets/images/teacher/teacher02.jpg',
        imgAlt: 'teacher',
        name: 'Mark Jukar',
        degi: 'Spanish Teacher',
        desc: 'Samet consectetuer apiscing elitsed diam nonumy nibh euismod nciduns awesome team mumber',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-pinterest-p',
                siteLink: '#',
                className: 'pinterest',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
    {
        imgUrl: 'assets/images/teacher/teacher03.jpg',
        imgAlt: 'teacher',
        name: 'Jesse Lingard',
        degi: 'Spanish Teacher',
        desc: 'Samet consectetuer apiscing elitsed diam nonumy nibh euismod nciduns awesome team mumber',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-pinterest-p',
                siteLink: '#',
                className: 'pinterest',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
    {
        imgUrl: 'assets/images/teacher/teacher04.jpg',
        imgAlt: 'teacher',
        name: 'Alison Tylor',
        degi: 'Spanish Teacher',
        desc: 'Samet consectetuer apiscing elitsed diam nonumy nibh euismod nciduns awesome team mumber',
        socialList: [
            {
                iconName: 'fab fa-facebook-f',
                siteLink: '#',
                className: 'facebook',
            },
            {
                iconName: 'fab fa-twitter',
                siteLink: '#',
                className: 'twitter',
            },
            {
                iconName: 'fab fa-pinterest-p',
                siteLink: '#',
                className: 'pinterest',
            },
            {
                iconName: 'fab fa-vimeo-v',
                siteLink: '#',
                className: 'vimeo',
            },
            {
                iconName: 'fab fa-behance',
                siteLink: '#',
                className: 'behance',
            },
        ],
    },
]

const Teacher = () => {
    return (
        <section className="teacher-section padding-bottom padding-top bg_img" style={{backgroundImage: "url(/assets/images/teacher/teacher-bg.jpg)"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-30-none justify-content-center">
                    {teacherList.map((val, i) => (
                        <div className="col-lg-6" key={i}>
                            <div className="teacher-item">
                                <div className="teacher-inner">
                                    <div className="teacher-thumb">
                                        <div className="thumb-inner">
                                            <Link to="/teacher-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                        </div>
                                    </div>
                                    <div className="teacher-content">
                                        <h6 className="title"><Link to="/teacher-single">{val.name}</Link></h6>
                                        <span className="info">{val.degi}</span>
                                        <p>{val.desc}</p>
                                        <ul className="teacher-social">
                                            {val.socialList.map((val, i) => (
                                                <li key={i}>
                                                    <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Teacher