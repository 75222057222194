import React from 'react';


const aboutCate = "About Our kitton";
const aboutTitle = "Special Attention For Every Child";
const aboutDesc = "Dolor sit amet consectetur elitery eiusmod tempor dunt aliqua utas enim veniam eiusmod tempor incididunt labore apidiously utilize functionr ideas for integrated information niqueliy disseinate proces-centric sources and backward compatible channels rofessionally repurpose schools business";
const quote = "“A Child Can Ask Questions That A Wise Man Cannot Answer.”";
const name = "Erma Bombeck";



const aboutList = [
    {
        text: 'Nead And Clean',
        spanText: 'Washroom',
    },
    {
        text: 'Canteen With Healthy',
        spanText: 'Foods And Water',
    },
    {
        text: 'Air Condition',
        spanText: 'Class Room',
    },
    {
        text: 'Large',
        spanText: 'Play Ground',
    },
]


const AboutThree = () => {
    return (
        <div className="padding-top padding-bottom about-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-thumb">
                            <img src="./assets/images/about/about02.png" alt="about" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header left-style mb-olpo">
                                <span className="cate">{aboutCate}</span>
                                <h3 className="title">{aboutTitle}</h3>
                                <p>{aboutDesc}</p>
                            </div>
                            <ul className="about-list">
                                {aboutList.map((val, i) => (
                                    <li key={i}>
                                        <i className="flaticon-vegan"></i>{val.text} <span> {val.spanText}</span>
                                    </li>
                                ))}
                            </ul>
                            <blockquote className="about-quote">
                                <div className="thumb">
                                    <img src="./assets/images/about/quote.png" alt="about" />
                                </div>
                                <div className="content">
                                    <span className="quote">{quote}</span> <span className="name">{name}</span>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutThree