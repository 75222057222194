import React from 'react';

const Search = () => {
    return (
        <div className="widget widget-search">
            <div className="widget-body">
                <form className="search-form">
                    <input type="text" placeholder="Search Here" />
                    <button type="submit"><i className="fas fa-search"></i></button>
                </form>
            </div>
        </div>
    )
}

export default Search;