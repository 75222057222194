import React from 'react'

const Pagination = () => {
    return (
        <ul className="default-pagination">
            <li>
                <a href="#"><i className="fas fa-angle-left"></i></a>
            </li>
            <li>
                <a href="#">01</a>
            </li>
            <li>
                <a href="#" className="active">02</a>
            </li>
            <li>
                <a href="#">03</a>
            </li>
            <li>
                <a href="#"><i className="fas fa-angle-right"></i></a>
            </li>
        </ul>
    )
}

export default Pagination