import React from 'react';


const title = "Leave a Comment";

const CommentFrom = () => {
    return (
        <div className="widget widget-comment">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <form className="comment-form">
                    <div className="form-group">
                        <input type="text" placeholder="Full Name" name="name" />
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Your Email" name="email" />
                    </div>
                    <div className="form-group w-100">
                        <textarea name="message" placeholder="Your Comments"></textarea>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="custom-button"><span>Send Comment</span></button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CommentFrom;