import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';


const cartList = [
    {
        imgUrl: 'assets/images/cart/01.png',
        imgAlt: 'cart',
        title: 'Baby Bag',
        price: '$250.00',
        totalPrice: '$500.00',
    },
    {
        imgUrl: 'assets/images/cart/02.png',
        imgAlt: 'cart',
        title: 'Baby Bag',
        price: '$250.00',
        totalPrice: '$500.00',
    },
    {
        imgUrl: 'assets/images/cart/03.png',
        imgAlt: 'cart',
        title: 'Baby Bag',
        price: '$250.00',
        totalPrice: '$500.00',
    },
    {
        imgUrl: 'assets/images/cart/04.png',
        imgAlt: 'cart',
        title: 'Baby Bag',
        price: '$250.00',
        totalPrice: '$500.00',
    },
    {
        imgUrl: 'assets/images/cart/05.png',
        imgAlt: 'cart',
        title: 'Baby Bag',
        price: '$250.00',
        totalPrice: '$500.00',
    },
]

const Cart = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Cart Page'} curPage={'Cart Page'} />
            <div className="shop-cart padding-top padding-bottom">
                <div className="container">
                    <div className="cart-top mb-widget">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartList.map((val, i) => (
                                    <tr key={i}>
                                        <td className="product-item-2">
                                            <div className="p-thumb">
                                                <Link to="/shop-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="p-content">
                                                <Link to="/shop-single">{val.title}</Link>
                                            </div>
                                        </td>
                                        <td>{val.price}</td>
                                        <td>
                                            <div className="cart-plus-minus">
                                                <div className="dec qtybutton">-</div>
                                                <input className="cart-plus-minus-box" type="text" name="qtybutton" defaultValue="2" />
                                                <div className="inc qtybutton">+</div>
                                            </div>
                                        </td>
                                        <td>{val.totalPrice}</td>
                                        <td>
                                            <a href="#"><img src="assets/images/cart/del.png" alt="product" /></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="cart-checkout-box mb-widget">
                        <div className="coupon-area">
                            <form className="coupon">
                                <input type="text" name="coupon" placeholder="Coupon Code..." className="cart-page-input-text" />
                                <input type="submit" defaultValue="Apply Coupon" />
                            </form>
                            <form className="cart-checkout">
                                <input type="submit" defaultValue="Update Cart" />
                                <input type="submit" defaultValue="Proceed to Checkout" />
                            </form>
                        </div>
                    </div>
                    <div className="shiping-box mb-widget">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="calculate-shiping">
                                    <h5 className="title">Calculate Shipping</h5>
                                    <form className="calculate-form">
                                        <div className="outline-select w-100">
                                            <select className="select-bar">
                                                <option defaultValue="volvo">United Kingdom (UK)</option>
                                                <option defaultValue="saab">Bangladesh</option>
                                                <option defaultValue="saab">Pakisthan</option>
                                                <option defaultValue="saab">India</option>
                                                <option defaultValue="saab">Nepal</option>
                                            </select>
                                        </div>
                                        <div className="outline-select shipping-select">
                                            <select className="select-bar">
                                                <option defaultValue="volvo">State/Country</option>
                                                <option defaultValue="saab">Dhaka</option>
                                                <option defaultValue="saab">Benkok</option>
                                                <option defaultValue="saab">Kolkata</option>
                                                <option defaultValue="saab">Kapasia</option>
                                            </select>
                                        </div>
                                        <input type="text" name="coupon" placeholder="Postcode/ZIP" />
                                        <button type="submit" className="custom-button"><span>Update Total</span></button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="cart-overview">
                                    <h5 className="title">Cart Totals</h5>
                                    <ul>
                                        <li>
                                            <span className="pull-left">Cart Subtotal</span>
                                            <p className="pull-right">$ 0.00</p>
                                        </li>
                                        <li>
                                            <span className="pull-left">Shipping and Handling</span>
                                            <p className="pull-right">Free Shipping</p>
                                        </li>
                                        <li>
                                            <span className="pull-left">Order Total</span>
                                            <p className="pull-right">$ 2940.00</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Cart;