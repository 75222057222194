import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import CountDown from '../component/sidebar/widget/countdown';
import CourseFeatures from '../component/sidebar/widget/course-features';
import GalleryPost from '../component/sidebar/widget/gallery-post';
import GoogleMap from '../component/sidebar/widget/google-map';
import PopularPost from '../component/sidebar/widget/popular-post';
import PopularTag from '../component/sidebar/widget/popular-tag';



const shareList = [
    {
        iconName: 'fab fa-facebook-f',
        className: 'facebook',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-dribbble',
        className: 'dribbble',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-google-plus-g',
        className: 'google',
        siteLink: '#',
    },
]

const tagsList = [
    {
        text: 'Schools',
        Link: '#',
    },
    {
        text: 'Education',
        Link: '#',
    },
    {
        text: 'Admission',
        Link: '#',
    },
    {
        text: 'Exams',
        Link: '#',
    },
]

const EventsSinglePage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Events Single Page'} curPage={'Events Single'} />
            <section className="events-single-section padding-top padding-bottom">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-lg-8 mb-50">
                            <div className="class-single-item">
                                <div className="class-single-inner">
                                    <div className="event-thumb">
                                        <img src="./assets/images/events/events-single.jpg" alt="events" />
                                        <div className="date-tag">
                                            <h3 className="title">04</h3>
                                            <span className="mon">May 20</span>
                                        </div>
                                    </div>
                                    <div className="class-single-content">
                                        <div className="countdown-area">
                                            <h4 className="counts-title">The event will start in :</h4>
                                            <CountDown />
                                        </div>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                        <blockquote className="single-quote">
                                            <div className="quotes">
                                                Steal into The nering Sanc How Mysef Down Amon The Hal Gras Buz
                                                Lttle World Amon The Stak And Grow Fama With Couns And Fesingri
                                                Steal intoing Thene Sanc Hrow Myse Down Amon The Hall Gras Biss
                                                The Almighty Among The Staks
                                                <span>...Melissa Hunter</span>
                                            </div>
                                        </blockquote>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                        <div className="maps single-page-maps">
                                            <GoogleMap />
                                        </div>
                                        <p>
                                            Serenity hassr taken posseson of my entire soung like these sweet mornngs sprng whch
                                            enjoy with
                                            my whole heart I am alonesi and feel the charm of exstenceths spot whch was the blis
                                            of souls like
                                            mineing am soo happy my dear frend absoribed the exquste sense enjoy with my whole
                                            heart in am
                                            alone and feel the charm of exstenceths spot whch was the blis of souls like mineing
                                            am so happyr
                                            my dear frend so absoribed the exqust sense tranquil existence that neglect my
                                            talentsr Ins should
                                            bye ncapable of drawng and singe wonderful serenty has taken possesison of my entrei
                                            souing like
                                            these sweet present moment and yet feel that never was greater artst
                                        </p>
                                    </div>
                                    <div className="tags-area">
                                        <ul className="tags">
                                            {tagsList.map((val, i) => (
                                                <li key={i}>
                                                    <a href={val.Link}>{val.text}</a>
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className="share">
                                            {shareList.map((val, i) => (
                                                <li key={i}>
                                                    <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-50">
                            <aside>
                                <CourseFeatures />
                                <PopularPost />
                                <GalleryPost />
                                <PopularTag />
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default EventsSinglePage;