
import {BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/layout/ScrollToTop";
import AboutPage from "./page/about";
import BlogClassic from "./page/blog-classic";
import BlogGrid from "./page/blog-grid";
import BlogSingle from "./page/blog-single";
import Cart from "./page/cart";
import ClassSchedule from "./page/class-schedule";
import ClassSinglePage from "./page/class-single";
import ClassesPage from "./page/classes";
import ComingSoon from "./page/coming-soon";
import ContactPage from "./page/contact";
import ErrorPage from "./page/error-page";
import EventsPage from "./page/events";
import EventsSinglePage from "./page/events-single";
import Faqs from "./page/faqs";
import FrgetPass from "./page/frgetpass";
import GalleryPage from "./page/gallery";
import GalleryTwo from "./page/gallery-2";
import GalleryThree from "./page/gallery-3";
import Home from "./page/home";
import HomeTwo from "./page/home-2";
import HomeThree from "./page/home-3";
import Login from "./page/login";
import Registration from "./page/registration";
import ShopPage from "./page/shop";
import ShopSingle from "./page/shop-single";
import TeacherPage from "./page/teacher";
import TeacherSingle from "./page/teacher-single";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/index-2" element={<HomeTwo />} />
				<Route path="/index-3" element={<HomeThree />} />
				<Route path="/classes" element={<ClassesPage />} />
				<Route path="/class-single" element={<ClassSinglePage />} />
				<Route path="/class-schedule" element={<ClassSchedule />} />
				<Route path="/about" element={<AboutPage />} />
				<Route path="/teacher" element={<TeacherPage />} />
				<Route path="/teacher-single" element={<TeacherSingle />} />
				<Route path="/events" element={<EventsPage />} />
				<Route path="/events-single" element={<EventsSinglePage />} />
				<Route path="/faqs" element={<Faqs />} />
				<Route path="/contact" element={<ContactPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/registration" element={<Registration />} />
				<Route path="/frgetpass" element={<FrgetPass />} />
				<Route path="/coming-soon" element={<ComingSoon />} />
				<Route path="*" element={<ErrorPage />} />
				<Route path="/gallery" element={<GalleryPage />} />
				<Route path="/gallery-2" element={<GalleryTwo />} />
				<Route path="/gallery-3" element={<GalleryThree />} />
				<Route path="/blog-grid" element={<BlogGrid />} />
				<Route path="/blog-classic" element={<BlogClassic />} />
				<Route path="/blog-single" element={<BlogSingle />} />
				<Route path="/shop" element={<ShopPage />} />
				<Route path="/shop-single" element={<ShopSingle />} />
				<Route path="/cart" element={<Cart />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
