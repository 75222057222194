import React from 'react';
import { Link } from 'react-router-dom';

const cate = "our blog post";
const title = "Our most popular posts";

const blogList = [
    {
        imgUrl: 'assets/images/blog/blog01.jpg',
        imgAlt: 'blog thumb',
        title: 'How Kids make sense of Life Experiences',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        comments: '2',
    },
    {
        imgUrl: 'assets/images/blog/blog02.jpg',
        imgAlt: 'blog thumb',
        title: 'Why do aerobicay fit children have better?',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        comments: '8',
    },
    {
        imgUrl: 'assets/images/blog/blog03.jpg',
        imgAlt: 'blog thumb',
        title: 'Why do aerobically have better Math Skills',
        date: 'April 04, 2022',
        author: 'Robot Smith',
        desc: 'Enthusiastically morph magnetic scenarios Uniquelly synthesize strategic theme areas vis parallel customer service.',
        comments: '5',
    },
]

const Blog = () => {
    return (
        <section className="blog-section padding-top padding-bottom pos-rel">
            <div className="top-shape-center">
                <img src="assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-30-none justify-content-center">
                    {blogList.map((val, i) => (
                        <div className="col-xl-4 col-md-6" key={i}>
                            <div className="post-item">
                                <div className="post-thumb">
                                    <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                </div>
                                <div className="post-content">
                                    <div className="post-top">
                                        <h5 className="title"><Link to="/blog-single">{val.title}</Link>
                                        </h5>
                                        <div className="post-meta cate">
                                            <a href="#"><i className="far fa-calendar"></i> {val.date}</a>
                                            <a href="#"><i className="fas fa-user"></i> {val.author}</a>
                                        </div>
                                        <p>{val.desc}</p>
                                    </div>
                                    <div className="post-bottom">
                                        <Link to="/blog-single" className="read">Read More</Link>
                                        <a href="#" className="comments"><i className="far fa-comments"></i> <span className="comment-number">{val.comments}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Blog