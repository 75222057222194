import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../sidebar/widget/pagination';

const EventsData = [
    {
        id: 1,
        cate: 'toddler',
        imgUrl: 'assets/images/events/events01.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 2,
        cate: 'kinder',
        imgUrl: 'assets/images/events/events02.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 3,
        cate: 'programe',
        imgUrl: 'assets/images/events/events03.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 4,
        cate: 'school',
        imgUrl: 'assets/images/events/events04.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 5,
        cate: 'toddler',
        imgUrl: 'assets/images/events/events05.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 6,
        cate: 'preschool',
        imgUrl: 'assets/images/events/events06.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 7,
        cate: 'kinder',
        imgUrl: 'assets/images/events/events07.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 8,
        cate: 'programe',
        imgUrl: 'assets/images/events/events08.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
    {
        id: 9,
        cate: 'school',
        imgUrl: 'assets/images/events/events09.jpg',
        imgAlt: 'events',
        date: '04',
        year: 'May 22',
        title: 'Father`s Day Sundaes And Shaving!',
        infoList: [
            {
                text: '08:00 am to 09:00 pm',
                iconName: 'fas fa-clock',
            },
            {
                text: 'National Auditorium, Dhaka.',
                iconName: 'fas fa-home',
            },
        ],
    },
]

const Events = () => {
    const [items, setItems] = useState(EventsData);
    const filterItem = (categItem) => {
        const updateItems = EventsData.filter((curElem) => {
            return curElem.cate === categItem;
        });
        setItems(updateItems);
    }

    return (
        <div className="events-section padding-top padding-bottom pos-rel">
            <div className="top-shape-center">
                <img src="./assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="bottom-shape-left">
                <img src="./assets/css/img/bottom-shape.png" alt="css" />
            </div>
            <div className="container">
                <ul className="filter">
                    <li onClick={() => setItems(EventsData) }>Show All</li>
                    <li onClick={() => filterItem('toddler') }>Toddler</li>
                    <li onClick={() => filterItem('preschool') }>Preschool</li>
                    <li onClick={() => filterItem('kinder') }>kindergarten</li>
                    <li onClick={() => filterItem('programe') }>pre-k Program</li>
                    <li onClick={() => filterItem('school') }>before/after school</li>
                </ul>
                <div className="class-wrapper d-flex flex-wrap justify-content-center">
                    { items.map((elem) => {
                        const { id, imgUrl, imgAlt, date, year, title, infoList } = elem;
                        return (
                            <div className="class-inner programe" key={id}>
                                <div className="event-item">
                                    <div className="event-thumb">
                                        <Link to="/events-single"><img src={imgUrl} alt={imgAlt} /></Link>
                                        <div className="date-tag">
                                            <h3 className="title">{date}</h3>
                                            <span className="mon">{year}</span>
                                        </div>
                                    </div>
                                    <div className="event-content">
                                        <h5 className="title">
                                            <Link to="/events-single">{title}</Link>
                                        </h5>
                                        <ul className="events-info">
                                            {infoList.map((val, i) => (
                                                <li key={i}><i className={val.iconName}></i> {val.text} </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>
                <Pagination />
            </div>
        </div>
    )
}

export default Events;