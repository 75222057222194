import React, { Fragment } from 'react';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

const cate = "Don’t Miss The Day";
const title = "Frequiently Ask Question";

const Faqs = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Frequiently Ask Question'} curPage={'Faqs'} />
            <section className="faqs-single-section padding-top padding-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-header">
                                <span className="cate">{cate}</span>
                                <h3 className="title">{title}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mb--10">
                        <div className="col-lg-6">
                            <div className="faq-wrapper">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accOne">
                                            <button className="accordion-button title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span className="right-icon"></span>
                                                Can i Attend a Class for Free?
                                            </button>
                                        </div>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="accOne" data-bs-parent="#accordionExample">
                                            <div className="faq-content">
                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accTwo">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <span className="right-icon"></span>
                                                Do I need to take the courses in a specific order?
                                            </button>
                                        </div>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="accTwo" data-bs-parent="#accordionExample">
                                            <div className="faq-content">
                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accThree">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <span className="right-icon"></span>
                                                Can i complete my admission process by online?
                                            </button>
                                        </div>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="faq-content">
                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accFour">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <span className="right-icon"></span>
                                                Can i complete my admission process by online?
                                            </button>
                                        </div>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="accFour" data-bs-parent="#accordionExample">
                                            <div className="faq-content">                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-wrapper">
                                <div className="accordion" id="accordionExampleTwo">
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accSecOne">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecOne" aria-expanded="true" aria-controls="collapseSecOne">
                                                <span className="right-icon"></span>
                                                Do I need to take the courses in a specific order?
                                            </button>
                                        </div>
                                        <div id="collapseSecOne" className="accordion-collapse collapse" aria-labelledby="accSecOne" data-bs-parent="#accordionExampleTwo">
                                            <div className="faq-content">
                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accSecTwo">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecTwo" aria-expanded="false" aria-controls="collapseSecTwo">
                                                <span className="right-icon"></span>
                                                Can i Attend a Class for Free
                                            </button>
                                        </div>
                                        <div id="collapseSecTwo" className="accordion-collapse collapse" aria-labelledby="accSecTwo" data-bs-parent="#accordionExampleTwo">
                                            <div className="faq-content">
                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accSecThree">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecThree" aria-expanded="false" aria-controls="collapseSecThree">
                                                <span className="right-icon"></span>
                                                Can i complete my admission process by online?
                                            </button>
                                        </div>
                                        <div id="collapseSecThree" className="accordion-collapse collapse" aria-labelledby="accSecThree" data-bs-parent="#accordionExampleTwo">
                                            <div className="faq-content">
                                                <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accSecFour">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecFour" aria-expanded="false" aria-controls="collapseSecFour">
                                                <span className="right-icon"></span>
                                                What is your Admission Process?
                                            </button>
                                        </div>
                                        <div id="collapseSecFour" className="accordion-collapse collapse" aria-labelledby="accSecFour" data-bs-parent="#accordionExampleTwo">
                                            <div className="faq-content">                                                
                                            <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item faq-item">
                                        <div className="faq-title" id="accSecFive">
                                            <button className="accordion-button title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecFive" aria-expanded="false" aria-controls="collapseSecFive">
                                                <span className="right-icon"></span>
                                                How can i become a instructor?
                                            </button>
                                        </div>
                                        <div id="collapseSecFive" className="accordion-collapse collapse" aria-labelledby="accSecFive" data-bs-parent="#accordionExampleTwo">
                                            <div className="faq-content">                                                
                                            <p>Global underwhels client-centric mindshare for proactive infomediary Uniquely leverage existing standardized web-readiness before end to Distinctively conceptualize worldwide schemas client-centered wasin end models readiness.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Faqs;