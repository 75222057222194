import React from 'react'

const Rating = () => {
    return (
        <div className="ratings">
            <span><i className="far fa-star"></i></span>
            <span><i className="far fa-star"></i></span>
            <span><i className="far fa-star"></i></span>
            <span><i className="far fa-star"></i></span>
            <span><i className="far fa-star"></i></span>
        </div>
    )
}

export default Rating